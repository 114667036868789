import { useEffect } from 'react'

// Define the types
type EffectCallback = () => void | Promise<void>

export function useDebouncedEffect(effect: EffectCallback, deps: React.DependencyList, delay: number): void {
  useEffect(() => {
    const handler = setTimeout(() => {
      effect()
    }, delay)

    // Clear the timeout if dependencies change
    return () => {
      clearTimeout(handler)
    }
  }, [...deps, delay]) // Ensure delay is part of dependencies
}
